<template>
    <div :class="$style['product']" v-on="$listeners">
        <NuxtLink
            :to="{ name: 'catalog-details-slug', params: { slug } }"
            :class="{
                [$style['preview']]: true,
                [$style['preview_roundings_none']]: $simlaweb.settings.roundings === ROUNDINGS.NONE,
                [$style['preview_roundings_small']]: $simlaweb.settings.roundings === ROUNDINGS.SMALL,
                [$style['preview_roundings_large']]: $simlaweb.settings.roundings === ROUNDINGS.LARGE
            }"
        >
            <ProductLabelList
                :class="$style['preview__labels']"
                :labels="labels"
            />

            <div :class="$style['preview__image-wrapper']">
                <SwImage
                    :class="$style['preview__image']"
                    :src="image"
                    alt="Photo"
                    width="-"
                    height="246"
                />
            </div>
        </NuxtLink>

        <div :class="$style['product__name']">
            <ProductName :title="name" :inverted="inverted" />
        </div>

        <ProductPrice
            :value="mainOfferPrice"
            :class="$style['product__price']"
            :inverted="inverted"
            bold
            large
        />

        <ProductAddControl
            :add-to-cart-btn-class="$style['product__add-to-cart-btn']"
            :offer-id="mainOffer.id"
            :store-quantity="mainOfferQuantity"
            :class="$style['product__buy']"
            :light="light"
            :small="small"
            :inverted="inverted"
            :disabled="disabled"
        />
    </div>
</template>

<script>
import ProductAddControl from '@@/website/components/common/ProductAddControl'
import ProductLabelList from '@@/website/components/common/ProductLabelList'
import ProductName from '@@/website/components/common/ProductName'
import ProductPrice from '@@/website/components/common/ProductPrice'

import { ROUNDINGS } from '@@/framework/types'

const findOfferInStock = offers => offers.find(offer => offer.quantity > 0) ?? null

export default {
  name: 'ProductItem',

  components: {
    ProductAddControl,
    ProductLabelList,
    ProductName,
    ProductPrice
  },

  props: {
    name: {
      type: String,
      default: 'Название товара'
    },

    slug: {
      type: String,
      default: ''
    },

    price: {
      type: Number,
      default: null
    },

    offers: {
      type: Array,
      required: true,
      validator: offers => offers.length > 0
    },

    storeQuantity: {
      type: Number,
      default: null
    },

    like: {
      type: Boolean,
      default: false
    },

    image: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    labels: {
      type: Array,
      default () {
        return []
      }
    },

    light: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ROUNDINGS: () => ROUNDINGS,

    mainOffer () {
      return findOfferInStock(this.offers) ?? this.offers[0]
    },

    mainOfferPrice () {
      if (this.price !== null) {
        return this.price
      }

      return this.mainOffer.price
    },

    mainOfferQuantity () {
      if (this.storeQuantity !== null) {
        return this.storeQuantity
      }

      return this.mainOffer.quantity
    }
  }
}
</script>

<style lang="less" module>
@import (reference) "./assets/Web.less";

.product {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 246px;
  // 100% - left margins (24px - every) between items (three in one row)
  @media screen and (max-width: @bp-tablet) { max-width: calc((100% - 72px) / 3); }
  // 100% - left margins (24px - every) between items (two in one row)
  @media screen and (max-width: 723.5px) { max-width: calc((100% - 48px) / 2); }
  @media screen and (max-width: 487.5px) { max-width: calc((100% - 16px) / 2); }

  &__name {
    flex: 1 0 auto;
  }

  &__price {
    display: block;
    line-height: 32px;

    @media screen and (max-width: 487.5px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__name,
  &__price,
  &__buy {
    margin: 8px 0;
  }

  & &__add-to-cart-btn {
    min-width: auto;
  }
}

.preview {
  display: block;
  background-color: transparent;
  text-decoration: none;
  position: relative;
  z-index: 1;

  &_roundings_none { border-radius: 0; }
  &_roundings_small { border-radius: 8px; }
  &_roundings_large { border-radius: 16px; }

  &__labels {
    position: absolute;
    top: -8px;
    left: 20px;
    width: calc(100% - 20px);
  }

  &__image-wrapper {
    position: relative;
    padding-top: 246px;
    max-width: 246px;
    margin: auto;
    overflow: hidden;

    @media screen and (max-width: 600px) {
      padding-top: 100%;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: auto;

    &:not(.sw-image-placeholder) {
      border-radius: 0;
    }
  }
}
</style>
