<template>
    <div :class="$style['control']">
        <SwButton
            v-if="!storeQuantity"
            :light="light"
            :small="small"
            :inverted="inverted"
            :transparent="inverted"
            disabled
        >
            {{ $t('product_out_of_stock') }}
        </SwButton>

        <template v-else-if="count">
            <SwInputNumber
                :value="count"
                :max="storeQuantity"
                :min="0"
                :size="small ? 'small' : 'large'"
                :inverted="inverted"
                :disabled="!storeQuantity || disabled"
                @changeInputNumber="change"
            />

            <div
                v-if="$route.name.includes('catalog-details-slug')"
                :class="[$style['control__count'], 'sw-text']"
            >
                {{ $tc('products_in_cart', count, { count }) }}
            </div>
        </template>

        <SwButton
            v-else
            :class="{
                [$style['control__add']]: true,
                [addToCartBtnClass]: !!addToCartBtnClass
            }"
            :light="light"
            :small="small"
            :inverted="inverted"
            :transparent="inverted"
            :disabled="disabled"
            @click="change(1)"
        >
            {{ $t('add_to_cart') }}
        </SwButton>
    </div>
</template>

<script>
import {
  CART_QUANTITY_CHANGE,
  GET_CART_ITEMS,
  GET_ORDER_TOTAL
} from '@@/graphQL/web'

export default {
  name: 'ProductAddControl',

  props: {
    offerId: {
      type: Number,
      default: null
    },

    quantity: {
      type: Number,
      default: 0
    },

    storeQuantity: {
      type: Number,
      default: 0
    },

    light: {
      type: Boolean,
      default: false
    },

    small: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    addToCartBtnClass: {
      type: String,
      default: '',
    }
  },

  data: () => ({
    cartItems: []
  }),

  computed: {
    count () {
      return this.cartItems.find(product => product.offer.id === this.offerId)?.quantity || 0
    },
  },

  apollo: {
    cartItems: {
      query: GET_CART_ITEMS,
      variables () {
        return {
          siteCode: this.$store.state.siteCode
        }
      }
    }
  },

  methods: {
    change (quantity) {
      if (!this.$store.state.admin) {
        if (this.offerId) {
          this.$apollo.mutate({
            mutation: CART_QUANTITY_CHANGE,
            variables: { id: this.offerId, quantity },
            refetchQueries: [{
              query: GET_CART_ITEMS,
              variables: {
                siteCode: this.$store.state.siteCode
              }
            }, {
              query: GET_ORDER_TOTAL,
              variables: {
                siteCode: this.$store.state.siteCode,
                code: this.$store.state.checkout.delivery.code,
                bonuses: this.$store.state.checkout.bonuses,
              }
            }]
          })
        }
      } else {
        this.$store.commit('modals/open', 'forbidden')
      }
    }
  }
}
</script>

<style lang="less" module>
.control {
  display: flex;
  flex-direction: column;
  position: relative;

  &__count {
    display: block;
    min-width: max-content;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    bottom: -24px;
  }
  @media screen and (max-width: 487.5px) {
    &__add {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
